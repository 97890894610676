import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment, useLayoutEffect } from 'react';
import Logo from '../../Images/logo.svg';
import { Link, useLocation } from 'react-router-dom';

const navigation = [
  { name: 'About us', href: '/about-us' },
  {
    name: 'Products',
    dropdown: [
      { href: '/forex-crm', label: 'Crypto For Brokers' },
      { href: '/p2p-system', label: 'P2P System' },
      { href: '/broker-tools', label: 'Tools for Brokers' },
    ],
  },
  // { name: "Our Clients", href: "/our-client" },
  { name: 'Contact us', href: '/contact-us' },
];

export default function ExampleHeader() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (document?.querySelector('#menu-button[aria-expanded="true"]')) {
      (document.querySelector('#menu-button') as any)?.click();
    }
  }, [location.pathname]);

  return (
    <div className='bg-[#507892] h-[55px] w-full text-white fixed top-0 z-50 shadow-[-1px_4px_20px_0px] shadow-[#507892]'>
      <Disclosure as='nav' className='h-full bg-[#06263Bb3]'>
        {({ open }) => (
          <>
            <div className='h-full top-0 mx-auto max-w-[1440px] px-2 sm:px-6 md:px-8'>
              <div className='relative flex h-full items-center justify-between'>
                {/* Hamburger button */}
                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                  <Disclosure.Button
                    id='menu-button'
                    className='inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-[#31556e] hover:text-white'
                  >
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='block h-6 w-6'
                        aria-hidden='true'
                      >
                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='block h-6 w-6'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                        />
                      </svg>
                    )}
                  </Disclosure.Button>
                </div>
                <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                  {/* Company logo */}
                  <div className='flex flex-shrink-0 items-center'>
                    <Link to='/'>
                      <img className='h-8 sm:h-10' src={Logo} alt='Your Company' />
                    </Link>
                  </div>
                  <div className='hidden ml-6 lg:ml-[75px] sm:block'>
                    <div className='flex'>
                      {navigation.map((item) =>
                        item.dropdown ? (
                          <Menu>
                            <div>
                              <Menu.Button
                                className={`flex ml-2 md:ml-3 hover:bg-[#31556e]   rounded-md px-3 py-2 text-sm font-medium ${
                                  location.pathname === '/forex-crm' ||
                                  location.pathname === '/p2p-system' ||
                                  location.pathname === '/broker-tools'
                                    ? 'text-[#1CA8DD] font-semibold'
                                    : 'text-white'
                                }`}
                              >
                                {item.name}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                  className='w-5 h-5 ml-1 mt-px'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                                    clipRule='evenodd'
                                  />
                                </svg>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter='transition ease-out duration-100'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'
                            >
                              <Menu.Items className='absolute mt-12 ml-24 w-36 bg-[#15374D] shadow-lg'>
                                <div className='px-1 py-1 '>
                                  {item.dropdown.map((subItem) => (
                                    <Menu.Item key={subItem.href} as={Fragment}>
                                      {({ active }) => (
                                        <Link
                                          to={subItem.href}
                                          className={`${active ? 'bg-[#31556e]' : 'bg-[#15374D]'} ${
                                            location.pathname === subItem.href
                                              ? 'text-[#1CA8DD] font-semibold'
                                              : 'text-white'
                                          } group flex w-full items-center px-2 py-2   text-sm`}
                                        >
                                          {subItem.label}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={`ml-2 md:ml-3 hover:bg-[#31556e]   rounded-md px-3 py-2 text-sm font-medium ${
                              location.pathname === item.href ? 'text-[#207DCE] font-semibold' : 'text-white '
                            }`}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className='w-28 sm:hidden'>
              <div className='flex flex-col px-2 pb-3 pt-2 bg-[#15374D]'>
                {navigation.map((item) =>
                  item.dropdown ? (
                    <Menu>
                      <div>
                        <Menu.Button
                          className={`flex mb-1 hover:bg-[#31556e]   rounded-md px-3 py-2 text-sm font-medium ${
                            location.pathname === '/forex-crm' ||
                            location.pathname === '/p2p-system' ||
                            location.pathname === '/broker-tools'
                              ? 'text-[#1CA8DD] font-semibold'
                              : 'text-white'
                          }`}
                        >
                          {item.name}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                            className='w-5 h-5 mt-px ml-0.5'
                          >
                            <path
                              fillRule='evenodd'
                              d='M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z'
                              clipRule='evenodd'
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='absolute mt-10 ml-24 w-36 bg-[#15374D] shadow-lg'>
                          <div className='px-1 py-1 '>
                            {item.dropdown.map((subItem) => (
                              <Menu.Item key={subItem.href} as={Fragment}>
                                {({ active }) => (
                                  <Link
                                    to={subItem.href}
                                    className={`${active ? 'bg-[#31556e]' : 'bg-[#15374D]'} ${
                                      location.pathname === subItem.href ? 'text-[#1CA8DD] font-semibold' : 'text-white'
                                    } group flex w-full items-center px-2 py-2   text-sm`}
                                  >
                                    {subItem.label}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`mb-1 hover:bg-[#31556e]   rounded-md px-3 py-2 text-sm font-medium ${
                        location.pathname === item.href ? 'text-[#1CA8DD] font-semibold' : 'text-white'
                      }`}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
