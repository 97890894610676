import background from '../../Images/Introduction-bg.png';
import Logo from '../../Images/logo.svg';

export default function Introduction() {
  var divStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  };
  return (
    <div id='Introduction' className='relative isolate overflow-hidden bg-[#111827] max-h-[624px] h-screen w-full'>
      <div style={divStyle}>
        <section className='flex flex-wrap items-center justify-center h-full bg-gradient-to-br from-[#1C3F5580] to-[#1c3f5580] to-[#1c3f5599] lg:justify-normal object-cover'>
          <div className='flex items-center h-full w-full mx-auto max-w-[1440px]'>
            <div className='flex flex-col text-center justify-center min-[1170px]:block min-[1170px]:w-1/2 min-[1170px]:text-left px-2 sm:px-6 md:px-8'>
              <img className='hidden sm:block h-[70px] mb-[10px]' src={Logo} alt='Logo' />
              <h2 className='text-2xl sm:text-3xl md:text-4xl font-bold leading-10 text-white'>
                Webbats IT Brokerage Solution
              </h2>
              <p className='mt-3 sm:mt-6 leading-5 sm:leading-6 font-medium sm:text-lg text-gray-300 min-[1170px]:w-[80%]'>
                At Webbats, we’re on a mission to change how clients manage their back-end. Our main goal is to create
                solutions that empower customers to grow up faster.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
