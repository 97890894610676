import headerimg from '../../Images/Forex1.png';
import img1 from '../../Images/Forex2.png';
import img2 from '../../Images/Forex3.png';
import img3 from '../../Images/Forex4.png';
import img4 from '../../Images/Forex5.png';

interface Section {
  header: string;
  text: string;
  dir: string;
  img: string;
  mx: string;
  bg: string;
}

const sections: Section[] = [
  {
    header: 'Revolutionizing Crypto Trading',
    text: 'Get ready for a game-changer! Our cutting-edge platform is built for brokers like you, offering smarter, faster, and more profitable trades. Stay ahead with real-time data, advanced analytics, and unbeatable security.',
    dir: 'md:flex-row',
    img: img1,
    mx: 'lg:ml-20',
    bg: 'bg-gradient-to-r',
  },
  {
    header: 'Game-Changing Low Fees',
    text: 'Say hello to higher profits with our groundbreaking fee structure. We’re slashing costs to give you the most competitive rates in the market. Imagine trading without worrying about fees eating into your earnings. How much more could you achieve with our game-changing low fees? Get ready to find out!',
    dir: 'md:flex-row-reverse',
    img: img2,
    mx: 'lg:mr-20',
    bg: 'bg-gradient-to-l',
  },
  {
    header: 'Tailored for Your Success',
    text: 'Every broker is unique, and our platform is designed with that in mind. From customizable features to personalized support, we’re here to elevate your trading experience. What if you had a platform that adapts to your style and needs? The future of personalized trading is almost here!',
    dir: 'md:flex-row',
    img: img3,
    mx: 'lg:ml-20',
    bg: 'bg-gradient-to-r',
  },
  {
    header: 'Join the Future of Trading',
    text: 'The future of crypto trading is coming, and it’s set to change everything. By joining us, you’re not just trading – you’re becoming part of a revolutionary movement. Stay tuned for our launch and be among the first to experience the extraordinary. Sign up now for exclusive updates and early access. Don’t miss out on this game-changer!',
    dir: 'md:flex-row-reverse',
    img: img4,
    mx: 'lg:mr-20',
    bg: 'bg-gradient-to-l',
  },
];

export default function ForexCrm() {
  var divStyle = {
    width: '100%',
    aspectRatio: '4.66',
    backgroundImage: `url(${headerimg})`,
  };
  return (
    <div className='flex flex-col bg-[#15374D] mt-[55px]'>
      <div className='relative bg-cover bg-right-top' style={divStyle}>
        <div className='flex flex-col items-center justify-center w-full h-full text-2xl sm:text-4xl font-bold leading-10 text-white bg-[#15374Dcc] z-10'>
          <h2>Crypto For Brokers</h2>
          <div className='bg-white w-12 sm:w-24 h-1.5 mt-2 sm:mt-3 md:mt-5'></div>
        </div>
      </div>
      {sections.map((section: Section, index: number) => (
        <section
          className={`flex flex-col-reverse ${section.dir} justify-center items-center md:mb-14 lg:h-[414px] mx-auto max-w-[1440px]`}
          key={index}
        >
          <div
            className={`flex flex-col items-baseline p-10 justify-center items-center text-white md:w-1/2 md:h-full ${section.bg} from-[#ffffff33] to-[#ffffff00]`}
          >
            <h2 className='text-2xl sm:text-3xl lg:text-4xl font-bold mb-3'>{section.header}</h2>
            <p className='text-sm sm:text-base font-normal'>{section.text}</p>
          </div>
          <div className={`md:w-1/2 ${section.mx} p-10 md:p-5 lg:p-10`}>
            <img src={section.img} alt={`img${index + 1}`} />
          </div>
        </section>
      ))}
    </div>
  );
}
