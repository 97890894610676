import {Link} from "react-router-dom";
import './thank-you.scss';
import connect from "../../Images/connect.png";

export default function ThankYou() {
  return (
    <div className='bg-[#1C3F55] mt-[55px] lg:h-[1257px] p-5 w-full thank-you-container'>
      <h1>Thank you for contacting us</h1>
      <p>We will come back to you soon</p>
      <Link to='/' className='md:my-2.5 text-sm sm:text-base hover:underline'>
        Go home
      </Link>
      <img src={connect} alt='connect' />
    </div>
  );
}
