import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Landing from '../pages/Landing/Landing';
import ContactUs from '../pages/ContactUs/ContactUs';
import AboutUs from '../pages/AboutUs/AboutUs';
import BrokerTools from '../pages/BrokerTools/BrokerTools';
import ForexCrm from '../pages/ForexCrm/ForexCrm';
import P2pSystem from '../pages/P2pSystem/P2pSystem';
import ThankYou from "../pages/ThankYou/ThankYou";

function Views() {
  return (
    <>
      <Header />
      <section>
        <Routes>
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/about-us' element={<AboutUs />} />
          {/* <Route path="/our-client" element={<OurClients />} /> */}
          <Route path='/broker-tools' element={<BrokerTools />} />
          <Route path='/forex-crm' element={<ForexCrm />} />
          <Route path='/p2p-system' element={<P2pSystem />} />
          <Route path='/thank-you' element={<ThankYou />} />
          <Route path='/' element={<Landing />} />
        </Routes>
      </section>
      <Footer />
    </>
  );
}

export default React.memo(Views);
