import headerimg from "../../Images/p2p1.png";
import img1 from "../../Images/p2p2.png";
import img2 from "../../Images/p2p3.png";
import img3 from "../../Images/p2p4.png";
import img4 from "../../Images/p2p5.png";

interface Section {
  header: string;
  text: string;
  dir: string;
  img: string;
  mx: string;
  bg: string;
}

const sections: Section[] = [
  {
    header: "P2P Platform for Seamless Trading",
    text: "Explore our user-friendly peer-to-peer (P2P) platform, designed to facilitate direct buying and selling of fiats/cryptocurrencies between individual traders. On the P2P platform, merchants can generate income by creating trading ads and executing transactions, seamlessly connecting your customers worldwide with local sellers within your broker.",
    dir: "md:flex-row",
    img: img1,
    mx: "lg:ml-20",
    bg: "bg-gradient-to-r",
  },
  {
    header: "The Vital Role of P2P for Brokers",
    text: "Integrating a P2P solution into your brokerage is crucial. It enables you to link local merchants with traders on your platform, creating a dynamic marketplace where individuals can engage in buying and selling. This integration significantly boosts traffic to your broker, fostering a vibrant trading community.",
    dir: "md:flex-row-reverse",
    img: img2,
    mx: "lg:mr-20",
    bg: "bg-gradient-to-l",
  },
  {
    header: "Unlocking Crypto Arbitrage Trading",
    text: "Dive into the world of crypto arbitrage trading—a strategy involving the purchase and sale of the same cryptocurrency across different exchanges at varying prices. This feature opens up new possibilities for traders seeking to capitalize on market inefficiencies and price differentials.",
    dir: "md:flex-row",
    img: img3,
    mx: "lg:ml-20",
    bg: "bg-gradient-to-r",
  },
  {
    header: "Arbitrage Opportunities with Fiat",
    text: "Extend your trading horizons with arbitrage not just in the crypto realm, but also with fiat currencies. Our platform facilitates arbitrage opportunities that enable traders to explore and leverage price variations across different fiat currencies, enhancing their trading strategies.",
    dir: "md:flex-row-reverse",
    img: img4,
    mx: "lg:mr-20",
    bg: "bg-gradient-to-l",
  },
];

export default function P2pSystem() {
  var divStyle = {
    width: "100%",
    aspectRatio: "4.66",
    backgroundImage: `url(${headerimg})`,
  };
  return (
    <div className="flex flex-col bg-[#15374D] mt-[55px]">
      <div className="relative bg-cover bg-right-top" style={divStyle}>
        <div className="flex flex-col items-center justify-center w-full h-full text-2xl sm:text-4xl font-bold leading-10 text-white bg-[#15374Dcc] z-10">
          <h2>P2P System</h2>
          <div className="bg-white w-12 sm:w-24 h-1.5 mt-2 sm:mt-3 md:mt-5"></div>
        </div>
      </div>
      {sections.map((section: Section, index: number) => (
        <section
          className={`flex flex-col-reverse ${section.dir} justify-center items-center md:mb-14 lg:h-[414px] mx-auto max-w-[1440px]`}
          key={index}
        >
          <div
            className={`flex flex-col items-baseline p-10 justify-center items-center text-white md:w-1/2 md:h-full ${section.bg} from-[#ffffff33] to-[#ffffff00]`}
          >
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-3">
              {section.header}
            </h2>
            <p className="text-sm sm:text-base font-normal">{section.text}</p>
          </div>
          <div className={`md:w-1/2 ${section.mx} p-10 md:p-5 lg:p-10`}>
            <img src={section.img} alt={`img${index + 1}`} />
          </div>
        </section>
      ))}
    </div>
  );
}
