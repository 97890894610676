import CTA from '../../Components/CTA/CTA';
import Introduction from '../../Components/Introduction/Introduction';
import LivePrices from '../../Components/LivePrices/LivePrices';
import Products from '../../Components/Products/Products';
import Solutions from '../../Components/Solutions/Solutions';
import Technologies from '../../Components/Technologies/Technologies';

function Landing() {
  return (
    <>
      <Introduction />
      <div className='desktop'>
        <LivePrices />
      </div>
      <CTA />
      <Products />
      <Solutions />
      <Technologies />
    </>
  );
}

export default Landing;
