import Broker from '../../Images/broker.png';
import { Link } from 'react-router-dom';

export default function CTA() {
  return (
    <div className='flex justify-center bg-gradient-to-r from-[#1C3F55] to-[#013640b3] xl:h-[624px]'>
      <div className='flex flex-col-reverse justify-center items-center md:flex-row h-full max-w-[1440px] p-2 sm:p-6 md:p-8'>
        <div className='flex flex-col items-baseline p-10 justify-center items-center text-white md:w-1/2 md:h-full bg-gradient-to-r from-[#ffffff33] to-[#ffffff00]'>
          <h2 className='text-xl sm:text-xl lg:text-3xl font-bold tracking-tight text-white'>
            Improve your broker performance, streamline everything and increase your income with our solutions
          </h2>
          <p className='mt-6 text-base sm:text-lg leading-8 text-gray-300'>
            The solutions we offer will ensure a smooth and great experience towards achieving your goals.
          </p>
          <div className='mt-10 flex items-center gap-x-6 lg:justify-start'>
            <Link
              to='/contact-us'
              className='border border-gray-100 rounded-md bg-[#1C3F55] px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-[#ffffff] shadow-sm hover:bg-[#31556e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
            >
              Contact us
            </Link>
            <a
              href='#Products'
              className='text-xs sm:text-sm font-semibold px-3.5 py-2.5 leading-6 text-white rounded-md hover:bg-[#31556e]'
            >
              Our Products <span aria-hidden='true'>→</span>
            </a>
          </div>
        </div>
        <div className='md:w-1/2 lg:ml-20'>
          <img className='w-full p-10 md:p-0 lg:p-10' src={Broker} alt='Broker' />
        </div>
      </div>
    </div>
  );
}
