import React from "react";
import liveprices from "../../Images/live-prices.svg";
import prices from "../../Images/prices.png";
import "./LivePrices.scss";

type PriceItem = {
  symbol: string;
  bid: number;
  ask: number;
  bidColor: string;
  askColor: string;
};

const initialPriceItem: PriceItem = {
  bid: 0,
  ask: 0,
  bidColor: "transparent",
  askColor: "transparent",
  symbol: "",
};

const getChangeColor = (oldValue: any, newValue: any): string => {
  if (oldValue === 0 || isNaN(oldValue) || isNaN(newValue)) {
    return "transparent";
  } else {
    return Number(newValue) > Number(oldValue)
      ? "#91d3aa"
      : Number(newValue) < Number(oldValue)
      ? "#ff9393"
      : "transparent";
  }
};

export default function LivePricesComponent() {
  // Crypto
  const [btcUsd, setBtcUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "BTC/USD",
  });
  const [btcEur, setBtcEur] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "BTC/EUR",
  });
  const [ethUsd, setEthUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "ETH/USD",
  });
  const [ethEur, setEthEur] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "ETH/EUR",
  });
  const [xrpUsd, setXrpUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "XRP/USD",
  });
  const [xrpEur, setXrpEur] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "XRP/EUR",
  });
  // Forex
  const [eurUsd, setEurUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "EUR/USD",
  });
  const [audUsd, setAudUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "AUD/USD",
  });
  const [gbpUsd, setGbpUsd] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "GBP/USD",
  });
  const [usdJpy, setUsdJpy] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "USD/JPY",
  });
  const [eurJpy, setEurJpy] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "EUR/JPY",
  });
  const [usdPln, setUsdPln] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "USD/PLN",
  });
  // Commodities
  const [gold, setGold] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "GOLD",
  });
  const [silver, setSilver] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "SILVER",
  });
  const [aluminium, setAluminium] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "ALUMINIUM",
  });
  const [zinc, setZinc] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "ZINC",
  });
  const [copper, setCopper] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "COPPER",
  });
  const [usOil, setUsOil] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "US OIL",
  });
  // Shares
  const [tesla, setTesla] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "TESLA",
  });
  const [amazon, setAmazon] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "AMAZON",
  });
  const [appl, setAppl] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "APPL",
  });
  const [msft, setMsft] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "MSFT",
  });
  const [goog, setGoog] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "GOOG",
  });
  const [baba, setBaba] = React.useState<PriceItem>({
    ...initialPriceItem,
    symbol: "BABA",
  });

  React.useEffect(() => {
    const wss = new WebSocket("wss://crm.webbats.com:8051");

    wss.onmessage = (event) => {
      const data = JSON.parse(event.data);

      data.forEach((priceItem: any) => {
        let bid = Number(priceItem.BidPrice);
        let ask = Number(priceItem.OfferPrice);

        switch (priceItem.Symbol) {
          case "BTCUSD":
            setBtcUsd((prev) => ({
              symbol: "BTC/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "BTCEUR":
            setBtcEur((prev) => ({
              symbol: "BTC/EUR",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "ETHUSD":
            setEthUsd((prev) => ({
              symbol: "ETH/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "ETHEUR":
            setEthEur((prev) => ({
              symbol: "ETH/EUR",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "XRPUSD":
            setXrpUsd((prev) => ({
              symbol: "XRP/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "XRPEUR":
            setXrpEur((prev) => ({
              symbol: "XRP/EUR",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "EURUSD":
            setEurUsd((prev) => ({
              symbol: "EUR/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "AUDUSD":
            setAudUsd((prev) => ({
              symbol: "AUD/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "GBPUSD":
            setGbpUsd((prev) => ({
              symbol: "GBP/USD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "USDJPY":
            setUsdJpy((prev) => ({
              symbol: "USD/JPY",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "EURJPY":
            setEurJpy((prev) => ({
              symbol: "EUR/JPY",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "USDPLN":
            setUsdPln((prev) => ({
              symbol: "USD/PLN",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "XAUUSD":
            setGold((prev) => ({
              symbol: "GOLD",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "XAGUSD":
            setSilver((prev) => ({
              symbol: "SILVER",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "ALUMINIUM":
            setAluminium((prev) => ({
              symbol: "ALUMINIUM",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "ZINC":
            setZinc((prev) => ({
              symbol: "ZINC",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "COPPER":
            setCopper((prev) => ({
              symbol: "COPPER",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "USOIL":
            setUsOil((prev) => ({
              symbol: "US OIL",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "TESLA.US":
            setTesla((prev) => ({
              symbol: "TESLA",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "AMZN.US":
            setAmazon((prev) => ({
              symbol: "AMAZON",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "AAPL.US":
            setAppl((prev) => ({
              symbol: "AAPL",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "MSFT.US":
            setMsft((prev) => ({
              symbol: "MSFT",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "GOOG.US":
            setGoog((prev) => ({
              symbol: "GOOG",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
          case "BABA.US":
            setBaba((prev) => ({
              symbol: "BABA",
              bidColor: getChangeColor(prev.bid, bid),
              askColor: getChangeColor(prev.ask, ask),
              bid,
              ask,
            }));
            break;
        }
      });
    };
  }, []);

  return (
    <div className="relative flex flex-wrap md:block content-center justify-center bg-white w-full min-h-[350px] md:min-h-[500px] overflow-hidden">
      <img
        className="hidden min-[999px]:block left-0 min-[999px]:absolute h-full"
        src={prices}
        alt="prices"
      />
      <section className="relative flex justify-center w-full min-[999px]:block dark:bg-gray-900 z-10">
        <div className="p-1 sm:p-4 md:p-5 m-[4px] sm:mx-auto min-[999px]:float-right md:w-1/2 max-w-screen-md price-table-conatiner">
          <img
            className="w-[60px] sm:w-[80px] md:w-[100px]"
            src={liveprices}
            alt="liveprices"
          />
          <p className="text-[17px] sm:text-xl tracking-tighter sm:tracking-normal mb-2 font-light text-center text-gray-500 dark:text-gray-400">
            Market pricing of the most important symbols
          </p>
          <div className="mb-4">
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li className="mr-2" role="presentation">
                <button
                  className="inline-block p-4 border-b-2 rounded-t-lg live-price-tab"
                  id="crypto-tab"
                  data-tabs-target="#crypto"
                  type="button"
                  role="tab"
                  aria-controls="crypto"
                  aria-selected="false"
                >
                  Crypto
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button
                  className="inline-block p-4 border-b-2 rounded-t-lg live-price-tab"
                  id="forex-tab"
                  data-tabs-target="#forex"
                  type="button"
                  role="tab"
                  aria-controls="forex"
                  aria-selected="false"
                >
                  Forex
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button
                  className="inline-block p-4 border-b-2 rounded-t-lg live-price-tab"
                  id="commodities-tab"
                  data-tabs-target="#commodities"
                  type="button"
                  role="tab"
                  aria-controls="commodities"
                  aria-selected="false"
                >
                  Commodities
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button
                  className="inline-block p-4 border-b-2 rounded-t-lg live-price-tab"
                  id="shares-tab"
                  data-tabs-target="#shares"
                  type="button"
                  role="tab"
                  aria-controls="shares"
                  aria-selected="false"
                >
                  Shares
                </button>
              </li>
            </ul>
          </div>
          <div id="myTabContent">
            <div
              className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="crypto"
              role="tabpanel"
              aria-labelledby="crypto-tab"
            >
              <table className="max-w-[300px] sm:max-w-none w-full overflow-auto text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-blue-200 text-cyan-800 text-sm text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Symbol
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bid
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ask
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {btcUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: btcUsd.bidColor }}
                    >
                      {btcUsd.bid ? btcUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: btcUsd.askColor }}
                    >
                      {btcUsd.ask ? btcUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {btcEur.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: btcEur.bidColor }}
                    >
                      {btcEur.bid ? btcEur.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: btcEur.askColor }}
                    >
                      {btcEur.ask ? btcEur.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {ethUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: ethUsd.bidColor }}
                    >
                      {ethUsd.bid ? ethUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: ethUsd.askColor }}
                    >
                      {ethUsd.ask ? ethUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {ethEur.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: ethEur.bidColor }}
                    >
                      {ethEur.bid ? ethEur.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: ethEur.askColor }}
                    >
                      {ethEur.ask ? ethEur.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {xrpUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: xrpUsd.bidColor }}
                    >
                      {xrpUsd.bid ? xrpUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: xrpUsd.askColor }}
                    >
                      {xrpUsd.ask ? xrpUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {xrpEur.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: xrpEur.bidColor }}
                    >
                      {xrpEur.bid ? xrpEur.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: xrpEur.askColor }}
                    >
                      {xrpEur.ask ? xrpEur.ask : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="forex"
              role="tabpanel"
              aria-labelledby="forex-tab"
            >
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-blue-200 text-cyan-800 text-sm text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Symbol
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bid
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ask
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {eurUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: eurUsd.bidColor }}
                    >
                      {eurUsd.bid ? eurUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: eurUsd.askColor }}
                    >
                      {eurUsd.ask ? eurUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {audUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: audUsd.bidColor }}
                    >
                      {audUsd.bid ? audUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: audUsd.askColor }}
                    >
                      {audUsd.ask ? audUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {gbpUsd.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: gbpUsd.bidColor }}
                    >
                      {gbpUsd.bid ? gbpUsd.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: gbpUsd.askColor }}
                    >
                      {gbpUsd.ask ? gbpUsd.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {usdJpy.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usdJpy.bidColor }}
                    >
                      {usdJpy.bid ? usdJpy.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usdJpy.askColor }}
                    >
                      {usdJpy.ask ? usdJpy.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {eurJpy.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: eurJpy.bidColor }}
                    >
                      {eurJpy.bid ? eurJpy.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: eurJpy.askColor }}
                    >
                      {eurJpy.ask ? eurJpy.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {usdPln.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usdPln.bidColor }}
                    >
                      {usdPln.bid ? usdPln.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usdPln.askColor }}
                    >
                      {usdPln.ask ? usdPln.ask : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="commodities"
              role="tabpanel"
              aria-labelledby="commodities-tab"
            >
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-blue-200 text-cyan-800 text-sm text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Symbol
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bid
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ask
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {gold.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: gold.bidColor }}
                    >
                      {gold.bid ? gold.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: gold.askColor }}
                    >
                      {gold.ask ? gold.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {silver.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: silver.bidColor }}
                    >
                      {silver.bid ? silver.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: silver.askColor }}
                    >
                      {silver.ask ? silver.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {aluminium.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: aluminium.bidColor }}
                    >
                      {aluminium.bid ? aluminium.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: aluminium.askColor }}
                    >
                      {aluminium.ask ? aluminium.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {zinc.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: zinc.bidColor }}
                    >
                      {zinc.bid ? zinc.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: zinc.askColor }}
                    >
                      {zinc.ask ? zinc.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {copper.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: copper.bidColor }}
                    >
                      {copper.bid ? copper.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: copper.askColor }}
                    >
                      {copper.ask ? copper.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {usOil.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usOil.bidColor }}
                    >
                      {usOil.bid ? usOil.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: usOil.askColor }}
                    >
                      {usOil.ask ? usOil.ask : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="shares"
              role="tabpanel"
              aria-labelledby="shares-tab"
            >
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-blue-200 text-cyan-800 text-sm text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Symbol
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bid
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ask
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {tesla.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: tesla.bidColor }}
                    >
                      {tesla.bid ? tesla.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: tesla.askColor }}
                    >
                      {tesla.ask ? tesla.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {amazon.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: amazon.bidColor }}
                    >
                      {amazon.bid ? amazon.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: amazon.askColor }}
                    >
                      {amazon.ask ? amazon.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {appl.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: appl.bidColor }}
                    >
                      {appl.bid ? appl.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: appl.askColor }}
                    >
                      {appl.ask ? appl.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {msft.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: msft.bidColor }}
                    >
                      {msft.bid ? msft.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: msft.askColor }}
                    >
                      {msft.ask ? msft.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {goog.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: goog.bidColor }}
                    >
                      {goog.bid ? goog.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: goog.askColor }}
                    >
                      {goog.ask ? goog.ask : "-"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {baba.symbol}
                    </th>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: baba.bidColor }}
                    >
                      {baba.bid ? baba.bid : "-"}
                    </td>
                    <td
                      className="px-6 py-4"
                      style={{ backgroundColor: baba.askColor }}
                    >
                      {baba.ask ? baba.ask : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export const LivePrices = React.memo(LivePricesComponent);
