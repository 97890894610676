import { BrowserRouter } from 'react-router-dom';
import Views from './Routes/Views';

function App() {
  return (
    <main className='app-container'>
      <BrowserRouter>
        <Views />
      </BrowserRouter>
    </main>
  );
}

export default App;
