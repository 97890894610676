import React from 'react';
import connect from '../../Images/connect.png';
import logo from '../../Images/wblogo.svg';
import axios from 'axios';
import { useNavigate } from "react-router-dom"

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
const apiEmailUrl =
  'https://email-function-240130175441.azurewebsites.net/api/contactuswebbats?code=2WSXIs4cx9C-SaH6fMyPEhcIaSXY_a7wUj_G7Oumc0d0AzFu-UVicg==';

export default function ContactUs() {
  const [showNameError, setShowNameError] = React.useState(true);
  const [showEmailError, setShowEmailError] = React.useState(true);
  const [showSubjectError, setShowSubjectError] = React.useState(true);
  const [showMessageError, setShowMessageError] = React.useState(true);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const isFormValid = isFormDirty && !showNameError && !showEmailError && !showSubjectError && !showMessageError;
  const navigate = useNavigate()

  const onNameKeyUp = (e: any) => {
    setIsFormDirty(true);
    const nameLength = e.target.value?.length;
    setShowNameError(nameLength > 100 || nameLength <= 0);
  };

  const onEmailKeyUp = (e: any) => {
    setIsFormDirty(true);
    const emailLength = e.target.value?.length;
    setShowEmailError(emailLength > 100 || emailLength <= 0 || !validateEmail(e.target.value));
  };

  const onSubjectKeyUp = (e: any) => {
    setIsFormDirty(true);
    const subjectLength = e.target.value?.length;
    setShowSubjectError(subjectLength > 300 || subjectLength <= 0);
  };

  const onMessageKeyUp = (e: any) => {
    setIsFormDirty(true);
    const messageLength = e.target.value?.length;
    setShowMessageError(messageLength > 2600 || messageLength <= 0);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(apiEmailUrl, {
        name: e.target.name.value,
        email: e.target.email.value,
        subject: e.target.subject.value,
        message: e.target.message.value,
      })
      .then(() => navigate("/thank-you"))
      .catch(() => setShowErrorMessage(true))
      .finally(() => {
        setTimeout(() => {
          setShowSuccessMessage(false);
          setShowErrorMessage(false);
        }, 5000);
      });
  };

  return (
    <div className='bg-[#1C3F55] mt-[55px] lg:h-[1257px] p-5 w-full'>
      {showSuccessMessage ? (
        <div
          className='fixed right-10 top-30 w-60 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded'
          role='alert'
        >
          <span className='block sm:inline'>Message was sent successfully!</span>
        </div>
      ) : null}
      {showErrorMessage ? (
        <div
          className='fixed right-10 top-30 w-60 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded'
          role='alert'
        >
          <span className='block sm:inline'>Something went wrong!</span>
        </div>
      ) : null}
      <section id='Contact' className='flex flex-col md:flex-row max-w-[1440px] mx-auto md:h-3/5'>
        <div className='flex flex-col justify-evenly lg:justify-between md:w-1/3 lg:w-1/2 text-gray-100 mb-6 sm:px-10 md:px-5 lg:px-20 sm:py-5 lg:py-10'>
          <h1 className='text-xl sm:text-3xl md:text-2xl lg:text-5xl font-bold leading-normal mb-6 md:w-2/3 xl:w-1/2'>
            Connect With Us
          </h1>
          <div className='grid grid-cols-2 md:flex md:flex-col lg:grid lg:grid-cols-2 mb-6 gap-x-6 gap-y-6 lg:gap-y-12'>
            <div className='text-sm sm:text-base'>
              <p>Sales</p>
              <p className='text-[#1CA8DD]'>sales@webbats.com</p>
            </div>
            <div className='text-sm sm:text-base'>
              <p>Support</p>
              <p className='text-[#1CA8DD]'>support@webbats.com</p>
            </div>
            <div className='text-sm sm:text-base'>
              <p>Phone</p>
              <p className='text-[#1CA8DD]'>+48534443464</p>
            </div>
          </div>
          <img src={connect} alt='connect' />
        </div>
        <div className='md:w-2/3 lg:w-1/2 px-6 sm:px-10 py-6 lg:mx-16 my-6 rounded-xl shadow-[1px_1px_30px_#ffffff1a]'>
          <h2 className='mb-4 text-3xl sm:text-4xl tracking-tight font-extrabold text-center text-gray-100 dark:text-white'>
            Contact Us
          </h2>
          <p className='mb-6 font-light text-center text-gray-100 dark:text-white sm:text-xl'>
            Inquiries Welcome, Solutions Guaranteed!
          </p>
          <form onSubmit={onFormSubmit}>
            <div className='mt-6'>
              <label htmlFor='name' className='block mb-2 text-sm font-medium text-gray-100 dark:text-gray-300'>
                Your full name
              </label>
              <input
                type='text'
                id='name'
                className='shadow-sm bg-[#F9FAFB1a] border border-gray-300 text-[#ffffff66] text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light'
                placeholder='Full name'
                required
                onKeyUp={onNameKeyUp}
              />
              {showNameError && isFormDirty ? (
                <span className='text-sm text-red-600 absolute'>Name is required</span>
              ) : null}
            </div>
            <div className='mt-6'>
              <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-100 dark:text-gray-300'>
                Your email
              </label>
              <input
                type='email'
                id='email'
                className='shadow-sm bg-[#F9FAFB1a] border border-gray-300 text-[#ffffff66] text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light'
                placeholder='name@example.com'
                required
                onKeyUp={onEmailKeyUp}
              />
              {showEmailError && isFormDirty ? (
                <span className='text-sm text-red-600 absolute'>Enter a valid email</span>
              ) : null}
            </div>
            <div className='mt-6'>
              <label htmlFor='subject' className='block mb-2 text-sm font-medium text-gray-100 dark:text-gray-300'>
                Subject
              </label>
              <input
                type='text'
                id='subject'
                className='block p-3 w-full text-sm text-[#ffffff66] bg-[#F9FAFB1a] rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light'
                placeholder='Let us know how we can help you'
                required
                onKeyUp={onSubjectKeyUp}
              />
              {showSubjectError && isFormDirty ? (
                <span className='text-sm text-red-600 absolute'>Subject is required</span>
              ) : null}
            </div>
            <div className='mt-6 sm:col-span-2'>
              <label htmlFor='message' className='block mb-2 text-sm font-medium text-gray-100 dark:text-gray-400'>
                Your message
              </label>
              <textarea
                id='message'
                rows={6}
                className='block p-2.5 w-full text-sm text-[#ffffff66] bg-[#F9FAFB1a] rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                placeholder='Leave a comment...'
                required
                onKeyUp={onMessageKeyUp}
              ></textarea>
              {showMessageError && isFormDirty ? (
                <span className='text-sm text-red-600 absolute'>Message is required</span>
              ) : null}
            </div>
            <button
              type='submit'
              className={`text-white border border-white ${
                isFormValid ? 'bg-[#1c3f55] hover:bg-[#31556e]' : 'bg-[#c1c1c1]'
              } focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 mb-5 dark:bg-blue-600 focus:outline-none dark:focus:ring-blue-800`}
              disabled={!isFormValid}
            >
              Send
            </button>
          </form>
        </div>
      </section>
      <div className='flex flex-col sm:flex-row justify-around items-center max-w-[1440px] mx-auto md:h-2/5'>
        <div className='bg-[#ffffff1a] text-[#ffffff] p-2 lg:p-5 mb-5 border border-white rounded lg:w-[40%]'>
          <div className='flex justify-between items-center'>
            <p className='lg:text-xl'>Head Office</p>
            <img src={logo} alt='logo' className='-m-2 scale-75 lg:scale-100' />
          </div>
          <h4 className='text-sm md:text-lg lg:text-2xl font-bold'>WEBBATS SPÓŁKA Z OGRANICZONĄ,</h4>
          <p className='text-sm md:text-lg lg:text-2xl'>ODPOWIEDZIALNOŚCIĄ</p>
          <p className='text-sm md:text-lg lg:text-2xl'>Hoża 86/41000-682</p>
          <p className='text-sm md:text-lg lg:text-2xl'>Warszawa, Polska</p>
        </div>
        <div className='bg-[#ffffff1a] text-[#ffffff] p-2 lg:p-5 mb-5 border border-white rounded lg:w-[40%]'>
          <div className='flex justify-between items-center'>
            <p className='lg:text-xl'>Head Office</p>
            <img src={logo} alt='logo' className='-m-2 scale-75 lg:scale-100' />
          </div>
          <h4 className='text-sm md:text-lg lg:text-2xl font-bold'>WEBBATS SPÓŁKA Z OGRANICZONĄ,</h4>
          <p className='text-sm md:text-lg lg:text-2xl'>UNIVERSITETO G.</p>
          <p className='text-sm md:text-lg lg:text-2xl'>16, 01122 </p>
          <p className='text-sm md:text-lg lg:text-2xl'>Vilnius, Lithuania</p>
        </div>
      </div>
    </div>
  );
}
