import { Link } from 'react-router-dom';
// import linkedin from '../../Images/Flinkedin.svg';
// import facebook from '../../Images/Ffacebook.svg';
// import twitter from '../../Images/Ftwitter.svg';
// import instagram from '../../Images/Finstagram.svg';

export default function Footer() {
  return (
    <footer className='bg-[#1C3F55]'>
      <div className='bg-[#ffffff0a]'>
        <div className='flex flex-col sm:flex-row justify-evenly sm:justify-between items-start sm:items-center py-2 text-white text-center max-w-[1440px] mx-auto px-4 sm:px-6 md:px-8'>
          <div className='flex flex-wrap items-start mt-4'>
            <div className='flex flex-col text-[#DDDDDD] text-left mr-10 mb-6'>
              <h3 className='text-white text-sm sm:text-base lg:text-lg font-bold md:my-4'>COMPANY</h3>
              <Link to='/about-us' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                About
              </Link>
              <Link to='/contact-us' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Contact
              </Link>
              {/* <Link to='#' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Community
              </Link> */}
            </div>
            <div className='flex flex-col text-[#DDDDDD] text-left mr-10 mb-6'>
              <h3 className='text-white text-sm sm:text-base lg:text-lg font-bold md:my-4'>PRODUCTS</h3>
              <Link to='/forex-crm' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Crypto For Brokers
              </Link>
              <Link to='/p2p-system' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                P2P System
              </Link>
              <Link to='/broker-tools' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Tools for Borkers
              </Link>
            </div>
            {/* TODO: brring back when the links are ready */}
            {/* <div className='flex flex-col text-[#DDDDDD] text-left h-full my-auto'>
              <h3 className='text-white text-sm sm:text-base lg:text-lg font-bold md:my-4'>LEARN</h3>
              <Link to='#' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Blog
              </Link>
              <Link to='#' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Support
              </Link>
              <Link to='#' className='md:my-2.5 text-sm sm:text-base hover:underline'>
                Cookie Policy
              </Link>
            </div> */}
            <div className='flex flex-col text-[#DDDDDD] text-left mr-10 mb-6'>
              <h3 className='text-white text-sm sm:text-base lg:text-lg font-bold md:my-4'>LEGAL</h3>
              <a
                href='https://webbats.com/aml-policy.pdf'
                target='_blank'
                className='md:my-2.5 text-sm sm:text-base hover:underline'
                rel='noreferrer'
              >
                AML Policy
              </a>
              <a
                href='https://webbats.com/terms-conditions.pdf'
                target='_blank'
                className='md:my-2.5 text-sm sm:text-base hover:underline'
                rel='noreferrer'
              >
                Terms and Conditions
              </a>
            </div>
          </div>
          <div className='flex flex-col flex-wrap content-center text-[#DDDDDD] sm:max-w-[50%] text-left h-full px-auto sm:ml-6 my-4 sm:my-8'>
            <h3 className='text-white text-lg font-bold mb-3'>Join the Conversation</h3>
            {/* TODO: bring the social media links when they are ready */}
            {/* <div className='flex flex-wrap items-center text-xs sm:text-sm font-medium text-white dark:text-gray-400 mb-3'>
              <a href='#'>
                <img src={linkedin} alt='linkedin' className='mr-1 sm:mr-4 md:mr-1 lg:mr-4 hover:scale-110' />
              </a>
              <a href='#'>
                <img src={twitter} alt='twitter' className='mr-1 sm:mr-4 md:mr-1 lg:mr-4 hover:scale-110' />
              </a>
              <a href='#'>
                <img src={instagram} alt='instagram' className='mr-1 sm:mr-4 md:mr-1 lg:mr-4 hover:scale-110' />
              </a>
              <a href='#'>
                <img src={facebook} alt='facebook' className='mr-1 sm:mr-4 md:mr-1 lg:mr-4 hover:scale-110' />
              </a>
            </div> */}
            <div>
              <div className='flex items-center mb-px'>
                <svg viewBox='0 0 24 24' fill='currentColor' height='1em' width='1em'>
                  <path d='M20 8l-8 5-8-5V6l8 5 8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2z' />
                </svg>
                <span className='text-white text-base font-normal leading-6 ml-2'></span>
                support@webbats.com
              </div>
              <div className='flex items-center mb-px'>
                <svg viewBox='0 0 16 16' fill='currentColor' height='1em' width='1em'>
                  <path
                    fill='currentColor'
                    d='M8 0a5 5 0 00-5 5c0 5 5 11 5 11s5-6 5-11a5 5 0 00-5-5zm0 8a3 3 0 110-6 3 3 0 010 6z'
                  />
                </svg>
                <h3 className='text-white text-sm leading-6 font-bold ml-2'>WEBBATS SPÓŁKA Z OGRANICZONĄ</h3>
              </div>

              <p className='text-white text-xs mb-px leading-5'>+48534443464</p>
              <p className='text-white text-xs mb-px leading-5'>ODPOWIEDZIALNOŚCIĄ</p>
              <p className='text-white text-xs mb-px leading-4'>Hoża 86/41000-682</p>
              <p className='text-white text-xs mb-px leading-4'>Warszawa, Polska</p>
              <p className='text-white text-xs mb-px leading-4'>NIP PL 7011058567</p>
            </div>
          </div>
        </div>
        <hr className='mt-3 border border-[#ffffff33]' />
        <div className='w-full h-full mx-auto max-w-[1440px] mx-auto px-2 sm:px-6 md:px-8 py-1 sm:py-4 flex items-center justify-between'>
          <span className='text-xs sm:text-sm text-white sm:text-center dark:text-gray-400'>
            © 2023{' '}
            <a href='/' className='hover:underline'>
              Webbats™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
