import ReactLogo from "../../Images/react.png";
import AngularLogo from "../../Images/angular.png";
import ScssLogo from "../../Images/scss-logo.png";
import GithubLogo from "../../Images/github.png";
import WebsocketLogo from "../../Images/websocket.png";
import JavaLogo from "../../Images/java.png";
import MySqlLogo from "../../Images/mysql-log.png";
import CSharpLogo from "../../Images/c-sharp.png";
import SpringLogo from "../../Images/spring.png";
import OurTechnologies from "../../Images/our-technologies.png";
import "./Technologies.scss";

export default function Technologies() {
  return (
    <div id="Technologies" className="py-8 lg:py-16">
      <div className="top-0">
        <div className="flex justify-center items-center pb-8">
          <div className="text-center">
            <img
              className="h-[50px] sm:h-[65px] md:h-[75px]"
              src={OurTechnologies}
              alt="our technologies"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <ul id="hexGrid">
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink react">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${ReactLogo})` }}
                />
                <h3 id="demo1">React</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink angular">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${AngularLogo})` }}
                />
                <h3 id="demo1">Angular</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink scss">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${ScssLogo})` }}
                />
                <h3 id="demo1">SCSS</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink websocket">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${WebsocketLogo})` }}
                />
                <h3 id="demo1">WebSocket</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink java">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${JavaLogo})` }}
                />
                <h3 id="demo1">Java</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink my-sql">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${MySqlLogo})` }}
                />
                <h3 id="demo1">MySQL</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink c-sharp">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${CSharpLogo})` }}
                />
                <h3 id="demo1">C#</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink spring">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${SpringLogo})` }}
                />
                <h3 id="demo1">Spring</h3>
              </span>
            </div>
          </li>
          <li className="hex">
            <div className="hexIn">
              <span className="hexLink github">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${GithubLogo})` }}
                />
                <h3 id="demo1">GitHub</h3>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
