import Product1 from '../../Images/Product1.png';
import Product2 from '../../Images/Product2.png';
import Product3 from '../../Images/Product3.png';
import OurProducts from '../../Images/our-products.png';
import { Link } from 'react-router-dom';

export default function Products() {
  return (
    <div id='Products' className='py-9 mx-auto max-w-[1440px]'>
      <div className='top-0 mx-auto'>
        <div className='flex justify-center items-center pb-2 md:pb-4 lg:pb-8'>
          <div className='text-center'>
            <img className='h-[50px] sm:h-[65px] md:h-[75px]' src={OurProducts} alt='our technologies' />
          </div>
        </div>
      </div>
      <div className='sm:mx-8 width-full pt-4 flex flex-col items-center lg:items-start lg:grid lg:gap-6 lg:grid-cols-3'>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Product1}
              alt='Product1'
            />
            <div className='sm:px-1 h-full sm:w-1/2 lg:w-full flex flex-col items-center'>
              <h5 className='mb-1 sm:mb-2 text-lg sm:text-2xl font-bold text-center sm:tracking-tight text-gray-900 dark:text-white'>
                Crypto For Brokers
              </h5>
              <p className='mb-3 text-base font-normal text-center leading-5 text-gray-700 dark:text-gray-400'>
                Get ready for a game-changer! Our cutting-edge platform is built for brokers like you, offering smarter,
                faster, and more profitable trades
              </p>
              <div className='flex-grow'></div>
              <Link
                to='/forex-crm'
                className='inline-flex items-center mb-2 sm:mb-0 px-1.5 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm font-normal sm:font-medium text-center text-white bg-[#1C3F55] rounded-lg hover:bg-[#31556e] focus:ring-4 focus:outline-none focus:ring-blue-300'
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Product2}
              alt='Product2'
            />
            <div className='sm:px-1 h-full sm:w-1/2 lg:w-full flex flex-col items-center'>
              <h5 className='mb-1 sm:mb-2 text-lg sm:text-2xl font-bold text-center sm:tracking-tight text-gray-900 dark:text-white'>
                P2P System
              </h5>
              <p className='mb-3 text-base font-normal text-center leading-5 text-gray-700 dark:text-gray-400'>
                Connect your customers from over the world with their local sellers, we provide you with different
                currencies including Fiat and Crypto.
              </p>
              <div className='flex-grow'></div>
              <Link
                to='/p2p-system'
                className='inline-flex items-center mb-2 sm:mb-0 px-1.5 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm font-normal sm:font-medium text-center text-white bg-[#1C3F55] rounded-lg hover:bg-[#31556e] focus:ring-4 focus:outline-none focus:ring-blue-300'
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Product3}
              alt='Product3'
            />
            <div className='sm:px-1 h-full sm:w-1/2 lg:w-full flex flex-col items-center'>
              <h5 className='mb-1 sm:mb-2 text-lg sm:text-2xl font-bold text-center sm:tracking-tight text-gray-900 dark:text-white'>
                Tools for Brokers
              </h5>
              <p className='mb-3 text-base font-normal text-center leading-5 text-gray-700 dark:text-gray-400'>
                We have a very strong team with very strong knowledge and experience which make us capable to develop
                very useful tools which fits your needs and requirements to monitor all the activities for MT5 Manager
              </p>
              <div className='flex-grow'></div>
              <Link
                to='/broker-tools'
                className='inline-flex items-center mb-2 sm:mb-0 px-1.5 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm font-normal sm:font-medium text-center text-white bg-[#1C3F55] rounded-lg hover:bg-[#31556e] focus:ring-4 focus:outline-none focus:ring-blue-300'
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
