import background from '../../Images/about-us-bg.png';
import background2 from '../../Images/background2.png';

export default function AboutUs() {
  var divStyle = {
    width: '100%',
    height: '100%',
    paddingTop: '30px',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  };
  var sectionStyle = {
    width: '100%',
    height: '100vh',
    maxHeight: '657px',
    backgroundImage: `url(${background2})`,
    backgroundSize: 'cover',
  };
  return (
    <div style={divStyle}>
      <section style={sectionStyle}>
        <div className='flex flex-col justify-start text-center max-w-[1440px] mx-auto lg:text-left'>
          <div className='lg:ml-[250px] lg:pt-20 lg:w-1/2 p-8 md:px-24 lg:p-0 lg:pr-16'>
            <h2 className='text-2xl sm:text-3xl font-bold leading-10 text-white'>About Us</h2>
            <p className='mt-1 sm:mt-2 leading-5 sm:leading-7 font-light text-lg sm:text-xl text-gray-300'>
              Webbats Company, an IT enterprise established in 2021 by the court in Warsaw with the registration number
              0000931924 and Tax number VAT NIP PL 7011058567, provides a variety of IT services tailored to meet the
              expectations and requirements of enterprises. At Webbats, we’re on a mission to change how clients manage
              their back-end. Our main goal is to create solutions that empower customers to grow up faster. Everything
              we do, develop, and think about is centered around helping our customers to grow their business by
              streamline everything, as securely and friction-free as possible. We were the first to create a plug-in
              marketplace, giving our customers a new level of freedom to scale the way they want. And we continue to
              develop innovative solutions that change the way brokerages manage their business with self-service
              solutions.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
