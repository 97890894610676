import headerimg from '../../Images/Broker1.png';
import img0 from '../../Images/Broker0.png';
import img1 from '../../Images/Broker2.png';
import img2 from '../../Images/Broker3.png';
import img3 from '../../Images/Broker4.png';
import img4 from '../../Images/Broker5.png';

interface Section {
  header: string;
  text: string;
  dir: string;
  img: string;
  mx: string;
  bg: string;
}

const sections: Section[] = [
  {
    header: 'Web Manger MT5',
    text: "Ready to turn your brokerage aspirations into reality? Reach out to us, and let's bring your requirements to life. Our team is committed to transforming your vision into tangible tools that enhance your business operations and propel you towards success.",
    dir: 'md:flex-row',
    img: img0,
    mx: 'lg:ml-20',
    bg: 'bg-gradient-to-r',
  },
  {
    header: 'Tailored Tools for Brokerage Success',
    text: "Unlock the full potential of your brokerage with our suite of custom tools, carefully designed to cater to your unique needs and requirements. Our expert team is dedicated to providing solutions that go beyond the ordinary, ensuring your brokerage stands out in the competitive landscape.",
    dir: 'md:flex-row-reverse',
    img: img1,
    mx: 'lg:mr-20',
    bg: 'bg-gradient-to-l',
  },
  {
    header: 'Comprehensive Monitoring for MT5 Manage',
    text: "Monitor and manage all activities seamlessly with our cutting-edge tools, specifically crafted for MT5 Manage. Gain unparalleled visibility into every facet of your operations, empowering you to make informed decisions and optimize your brokerage's performance.",
    dir: 'md:flex-row',
    img: img2,
    mx: 'lg:ml-20',
    bg: 'bg-gradient-to-r',
  },
  {
    header: 'Your Vision, Our Mission',
    text: "Ready to turn your brokerage aspirations into reality? Reach out to us, and let's bring your requirements to life. Our team is committed to transforming your vision into tangible tools that enhance your business operations and propel you towards success.",
    dir: 'md:flex-row-reverse',
    img: img3,
    mx: 'lg:mr-20',
    bg: 'bg-gradient-to-l',
  },
  {
    header: 'Streamlining for Growth',
    text: "At the core of everything we do is a commitment to your growth. Our tools are not just about functionality; they're about streamlining your processes for maximum efficiency. From development to implementation, our focus is on helping you grow your business by simplifying and enhancing every aspect of your operations.",
    dir: 'md:flex-row',
    img: img4,
    mx: 'lg:ml-20',
    bg: 'bg-gradient-to-r',
  },
];

export default function BrokerTools() {
  var divStyle = {
    width: '100%',
    aspectRatio: '4.66',
    backgroundImage: `url(${headerimg})`,
  };
  return (
    <div className='flex flex-col bg-[#15374D] mt-[55px]'>
      <div className='relative bg-cover bg-right-top' style={divStyle}>
        <div className='flex flex-col items-center justify-center w-full h-full text-2xl sm:text-4xl font-bold leading-10 text-white bg-[#15374Dcc] z-10'>
          <h2>Tools for Brokers</h2>
          <div className='bg-white w-12 sm:w-24 h-1.5 mt-2 sm:mt-3 md:mt-5'></div>
        </div>
      </div>
      {sections.map((section: Section, index: number) => (
        <section
          className={`flex flex-col-reverse ${section.dir} justify-center items-center md:mb-14 lg:h-[414px] mx-auto max-w-[1440px]`}
          key={index}
        >
          <div
            className={`flex flex-col items-baseline p-10 justify-center items-center text-white md:w-1/2 md:h-full ${section.bg} from-[#ffffff33] to-[#ffffff00]`}
          >
            <h2 className='text-2xl sm:text-3xl lg:text-4xl font-bold mb-3'>{section.header}</h2>
            <p className='text-sm sm:text-base font-normal'>{section.text}</p>
          </div>
          <div className={`md:w-1/2 ${section.mx} p-10 md:p-5 lg:p-10`}>
            <img src={section.img} alt={`img${index + 1}`} />
          </div>
        </section>
      ))}
    </div>
  );
}
